import { createGlobalStyle } from "styled-components"

const SharedStyles = createGlobalStyle`
    html{
        scroll-behavior: smooth;
        font-size: 16px;
        --c-mint-100: #00e2c1;
        --c-green-200: #00c3a6;
        --c-blue-100: #12171F; 
        --c-blue-101: #131c2d; 
        --c-blue-200: #0d1016; 
        --c-blue-201: #0a0e15; 
        --c-black: #000; 
        --c-white: #d7d7d7;

        .ixs-mobile-img {
            display: none !important;
        }
        .x-img{
            width: 100%;
        }


        @media (min-width: 1900px) {
            font-size: 20px;
            .ixs-mobile-img {
                display: none !important;
            }
        }

        @media (min-width: 1500px) {
            font-size: 18px;
            .ixs-mobile-img {
                display: none !important;
            }
        }

        @media (min-width: 1919px) {
            font-size: 24px;

            .ixs-mobile-img {
                display: none !important;
            }
        }

        @media (max-width: 1199px) {
            font-size: 12px;
            .ixs-mobile-img {
                display: none !important;
            }
        }

        @media (max-width: 766px) {
            font-size: 15px;

            .xs-mobile {
                display: none;
            }
            .ixs-mobile-img {
                display: block!important;
                margin-bottom: -320px!important;
            }
            .ixs-mobile-container-margin {
                margin-top: 100px!important;
            }
            h1 {
                font-size: 1.4rem!important;
            }

            .xld-image{
                max-width: 134%;
                margin-left: -68px;
                margin-bottom: -95px;
            }


            .android-store {
                width: 150px!important;
            }

            .apple-store {
                width: 135px!important;
            }

            .xh1 {
                font-size: 1.4rem !important;
            }
        }

        @media (prefers-color-scheme: light){
            --c-white: #f2f2f2; 
        }

        &.is-light {
            --c-white: #0d1016 !important; 
        }
    }
     body {
        -webkit-font-smoothing: antialiased;
        font-family: var(--xn-font-body);
        font-weight: 400;
        background: var(--c-blue-200);
        color: var(--c-white); 

        @media (prefers-color-scheme: light){
            background: var(--c-white); 
            color: var(--c-blue-200);
        }
    }

    h1,h2{
        font-family: var(--xn-font-title);
        font-weight: 700;
    }

    .xh1 {
        font-family: var(--xn-font-body), sans-serif!important;
        font-weight: 700!important;
        font-size: 2rem!important;
    }

    .gatsby-image-wrapper {
        height: 100%; 
    }
`
export default SharedStyles
